<template>
  <v-container>
    <v-row class="mb-4">
      <h2>Density of Liquids and Solids - Report Sheet</h2>
    </v-row>
    <v-row>
      <p class="mb-3">
        a) Fill in the table below using the data from each of the 5 trials you performed in lab.
        Use the temperature of the water and the table of densities in your lab manual to calculate
        an actual water density. Then use the mass of the water from each trial to calculate a
        "corrected volume" for <b>your</b> 10 mL pipet. Since your temperatures should have one
        decimal place and the densities in the lab manual do not, you will have to interpolate the
        correct density before entering it into the data table.
      </p>
    </v-row>

    <v-row class="mb-7">
      <v-simple-table>
        <thead>
          <tr>
            <td>
              <stemble-latex content="$\text{ }$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Trial}\ $" />
              <stemble-latex content="$\text{1}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Trial}\ $" />
              <stemble-latex content="$\text{2}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Trial}\ $" />
              <stemble-latex content="$\text{3}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Trial}\ $" />
              <stemble-latex content="$\text{4}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\text{Trial}\ $" />
              <stemble-latex content="$\text{5}$" />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\text{Dry}\ $" />
              <stemble-latex content="$\text{Cylinder}\,$" />
              <stemble-latex content="$\text{(g)}$" />
            </td>
            <td>
              <v-text-field
                v-model="inputs.dryCylinderT1"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.dryCylinderT1"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.dryCylinderT2"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.dryCylinderT2"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.dryCylinderT3"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.dryCylinderT3"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.dryCylinderT4"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.dryCylinderT4"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.dryCylinderT5"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.dryCylinderT5"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Cylinder}\ $" />
              <stemble-latex content="$\text{+ Water}\,$" />
              <stemble-latex content="$\text{(g)}$" />
            </td>
            <td>
              <v-text-field
                v-model="inputs.wetCylinderT1"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.wetCylinderT1"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.wetCylinderT2"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.wetCylinderT2"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.wetCylinderT3"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.wetCylinderT3"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.wetCylinderT4"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.wetCylinderT4"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.wetCylinderT5"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.wetCylinderT5"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Water}\,$" />
              <stemble-latex content="$\text{(g)}$" />
            </td>
            <td>
              <v-text-field
                v-model="inputs.waterMassT1"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.waterMassT1"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.waterMassT2"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.waterMassT2"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.waterMassT3"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.waterMassT3"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.waterMassT4"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.waterMassT4"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.waterMassT5"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.waterMassT5"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Water}\ $" />
              <stemble-latex content="$\text{Temp}\,$" />
              <stemble-latex content="$\text{(}^\circ\text{C)}$" />
            </td>
            <td>
              <v-text-field
                v-model="inputs.waterTempT1"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.waterTempT1"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.waterTempT1"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.waterTempT1"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.waterTempT1"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.waterTempT1"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.waterTempT1"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.waterTempT1"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.waterTempT1"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.waterTempT1"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Density}\,$" />
              <stemble-latex content="$\text{(g/mL)}$" />
            </td>
            <td>
              <v-text-field
                v-model="inputs.densityT1"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.densityT1"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.densityT1"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.densityT1"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.densityT1"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.densityT1"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.densityT1"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.densityT1"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.densityT1"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.densityT1"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Corrected}\ $" />
              <stemble-latex content="$\text{Pipet}\ $" />
              <stemble-latex content="$\text{Volume}\,$" />
              <stemble-latex content="$\text{(mL)}$" />
            </td>
            <td>
              <v-text-field
                v-model="inputs.correctedVolumeT1"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.correctedVolumeT1"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.correctedVolumeT2"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.correctedVolumeT2"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.correctedVolumeT3"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.correctedVolumeT3"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.correctedVolumeT4"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.correctedVolumeT4"
              />
            </td>
            <td>
              <v-text-field
                v-model="inputs.correctedVolumeT5"
                class="centered-input"
                :readonly="isMarking"
                input="inputs.correctedVolumeT5"
              />
            </td>
          </tr>
        </thead>
      </v-simple-table>
    </v-row>

    <p>
      b) What is the average "corrected volume" of your pipet, over the 5 trials, based on the
      results tabulated in the final row of the table above?
    </p>

    <calculation-input
      v-model="inputs.correctedVolumeAvg"
      class="mb-1"
      prepend-text="$\text{Corrected Volume:}$"
      append-text="$\text{mL}$"
      :disabled="!allowEditing"
    />
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput';

export default {
  name: 'OleMissExperiment3ReportSheet',
  components: {
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        dryCylinderT1: null,
        dryCylinderT2: null,
        dryCylinderT3: null,
        dryCylinderT4: null,
        dryCylinderT5: null,

        wetCylinderT1: null,
        wetCylinderT2: null,
        wetCylinderT3: null,
        wetCylinderT4: null,
        wetCylinderT5: null,

        waterMassT1: null,
        waterMassT2: null,
        waterMassT3: null,
        waterMassT4: null,
        waterMassT5: null,

        densityT1: null,
        waterTempT1: null,

        correctedVolumeT1: null,
        correctedVolumeT2: null,
        correctedVolumeT3: null,
        correctedVolumeT4: null,
        correctedVolumeT5: null,
        correctedVolumeAvg: null,
      },
    };
  },
};
</script>

<style>
input[type='number'] {
  -moz-appearance: textfield; /*For FireFox*/

  &::-webkit-inner-spin-button {
    /*For Webkits like Chrome and Safari*/
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
